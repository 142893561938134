<template>
    <b-container fluid>
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{$t('elearning_tim.id_card')}}</h4>
          </template>
            <template v-slot:body>
              <searchBasic :search="search" @search-data="searchIt"/>
              <b-col lg="12" sm="12" class="text-right">
                <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
              </b-col>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('elearning_tim.id_card')}}</h4>
                    </template>
                    <template v-slot:headerAction1>

                    </template>
                    <template v-slot:headerAction>
                        <!-- <b-button variant="primary btn_add_new" @click="pdfExport" class="mr-2">
                          <i class="fas fa-print"></i>{{  $t('globalTrans.print') }}
                        </b-button> -->
                        <b-button type="button" variant="primary" class="ml-2" @click="pdfExportID">{{ $t('globalTrans.download') }} {{ $t('elearning_tim.name_badge') }}</b-button>
                        <!-- <b-button type="button" variant="primary" class="ml-2" @click="pdfExportIdWord">{{ $t('globalTrans.download') }} {{ $t('elearning_tim.name_badge') }} {{ $t('globalTrans.word') }}</b-button> -->
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loading">
                          <b-row>
                              <b-col md="9" class="mb-2 text-danger">
                                <b>{{ $t('globalTrans.note') }} (<span class="text-danger">***</span>) {{ $t('elearning_tim.name_badge_alert') }}</b>
                              </b-col>
                              <b-col md="3" class="mb-2 text-right">
                                <b-form-checkbox
                                  style="margin-top: 5px"
                                  class="mb-3"
                                  name="checked"
                                  v-model="selectAll"
                                  @change="checkAll"
                                  :value=true
                                  :unchecked-value=false
                                >
                                <span>{{ $t('globalTrans.checkAll') }}</span>
                                </b-form-checkbox>
                              </b-col>
                            </b-row>
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                      <template v-slot:cell(index)="data">
                                      {{ $n(data.index + pagination.slOffset) }}
                                      </template>
                                      <!-- <template v-slot:cell(org_name)="data">
                                        <span v-if="data.item.profession_type != 1">
                                          {{ ($i18n.locale=='bn') ? data.item.professional_other_org_name_bn : data.item.professional_other_org_name }}
                                        </span>
                                        <span v-if="data.item.profession_type == 1">
                                          {{ ($i18n.locale=='bn') ? data.item.org_bn : data.item.org}}
                                        </span>
                                      </template> -->
                                      <!-- <template v-slot:cell(professional_org_id)="data">
                                        <span v-if="data.item.professional_org_id">
                                          {{ getOrgName(data.item.professional_org_id) }}
                                        </span>
                                        <span v-else>
                                          {{ ($i18n.locale=='bn') ? data.item.org_name_bn : data.item.org_name_en}}
                                        </span>
                                      </template> -->
                                      <template v-slot:cell(office)="data">
                                        <!-- {{data.item}} -->
                                        <span v-if="data.item.profession_type != 1 && data.item.not_here_office == 0">
                                          {{ getOfficeName(data.item.professional_office_id) }}
                                        </span>
                                        <span v-if="data.item.profession_type != 1 &&  data.item.not_here_office == 1">
                                          {{ ($i18n.locale == 'en' ? data.item.office_name : data.item.office_name_bn) }}<br/>
                                        </span>
                                        <span v-if="data.item.profession_type == 1 && data.item.not_here_office == 1">
                                          {{ ($i18n.locale == 'en' ? data.item.office_name : data.item.office_name_bn) }}<br/>
                                        </span>
                                        <!-- <span v-if="data.item.profession_type != 1">{{ ($i18n.locale=='bn') ? data.item.org_name_bn : data.item.org_name_en }}</span> -->
                                        <!-- <span v-if="data.item.professional_office_id">
                                          {{ getOfficeName(data.item.professional_office_id) }}
                                        </span>
                                        <span v-else>
                                          {{ ($i18n.locale=='bn') ? data.item.designation_bn : data.item.designation_en}}
                                        </span> -->
                                      </template>
                                      <template v-slot:cell(designation)="data">
                                        {{ getDesignationData(data.item, 'admin') }}
                                        <!-- <pre>{{ data.item }}</pre> -->
                                        <!-- <span v-if="data.item.designation_id && data.item.not_here_designation == 0 && data.item.profession_type == 1">{{ getDesignation(data.item.designation_id) }}</span>
                                        <span v-else>{{ ($i18n.locale=='bn') ? data.item.designation_bn : data.item.designation_en }}</span> -->
                                        <!-- <span v-if="data.item.designation_id">
                                          {{ getDesignation(data.item.designation_id) }}
                                        </span>
                                        <span v-else>
                                          {{ ($i18n.locale=='bn') ? data.item.designation_bn : data.item.designation_en}}
                                        </span> -->
                                      </template>
                                      <template v-slot:cell(email)="data">
                                        <span>{{ data.item.email }}</span>
                                      </template>
                                      <template v-slot:cell(circular_memo_no)="data">
                                        <span>{{ data.item.circular_memo_no }}</span>
                                      </template>
                                      <template v-slot:cell(action)="data">
                                        <b-form-group class="mb-0" v-slot="{ ariaDescribedby }">
                                          <b-form-checkbox-group v-model="selectedItem" style="margin-top: 5px" :aria-describedby="ariaDescribedby">
                                            <b-form-checkbox :value="data.item.id" style="margin-right: 0"></b-form-checkbox>
                                          </b-form-checkbox-group>
                                        </b-form-group>
                                        <a href="javascript:" title="Print" v-b-modal.modal-4 class="btn_table_action table_action_view" size="sm" @click="edit(data.item)"><i class="fas fa-print"></i></a>
                                      </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>

    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import Form from './Form'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { traineeIdCards, idCardPDFList, idCardWordList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import ExportPdf from './export_pdf_details'
import searchBasic from './../../../component/searchBasic'

export default {
    mixins: [ModalBaseMasterList],
    components: {
      Form,
      searchBasic
    },
    data () {
      return {
        search: {
          org_id: null,
          coordinator_id: 0,
          fiscal_year_id: 0,
          circular_memo_no: '0',
          training_type_id: null,
          training_category_id: null,
          training_title_id: null,
          batch_no: 0,
          sort_status: 2,
          registration_for: 2
        },
        trainingCategoryList: [],
        trainingTitleList: [],
        trainerNameList: [],
        trainerList: [],
        circularList: [],
        allBatchListData: [],
        trainingTypeList: [],
        batchList: [],
        selectedItem: [],
        selectAll: false,
        designation_data: '',
        loading: false
      }
    },
    computed: {
      allBatchList () {
        return [
          { value: 1, text: this.$i18n.locale === 'en' ? '1st' : '১ম', text_en: '1st', text_bn: '১ম' },
          { value: 2, text: this.$i18n.locale === 'en' ? '2nd' : '২য়', text_en: '2nd', text_bn: '২য়' },
          { value: 3, text: this.$i18n.locale === 'en' ? '3rd' : '৩য়', text_en: '3rd', text_bn: '৩য়' },
          { value: 4, text: this.$i18n.locale === 'en' ? '4th' : '৪র্থ', text_en: '4th', text_bn: '৪র্থ' },
          { value: 5, text: this.$i18n.locale === 'en' ? '5th' : '৫ম', text_en: '5th', text_bn: '৫ম' },
          { value: 6, text: this.$i18n.locale === 'en' ? '6th' : '৬ষ্ঠ', text_en: '6th', text_bn: '৬ষ্ঠ' },
          { value: 7, text: this.$i18n.locale === 'en' ? '7th' : '৭ম', text_en: '7th', text_bn: '৭ম' },
          { value: 8, text: this.$i18n.locale === 'en' ? '8th' : '৮ম', text_en: '8th', text_bn: '৮য়' },
          { value: 9, text: this.$i18n.locale === 'en' ? '9th' : '৯ম', text_en: '9th', text_bn: '৯ম' },
          { value: 10, text: this.$i18n.locale === 'en' ? '10th' : '১০ম', text_en: '10th', text_bn: '১০ম' }
        ]
      },
      userTypeList () {
        const userList = [
          { value: 1, text: this.$i18n.locale === 'en' ? 'Trainer' : 'প্রশিক্ষক', text_en: 'Trainer', text_bn: 'প্রশিক্ষক' },
          { value: 2, text: this.$i18n.locale === 'en' ? 'Trainee' : 'প্রশিক্ষণার্থী', text_en: 'Trainee', text_bn: 'প্রশিক্ষণার্থী' }
        ]
        return userList
      },
      orgList: function () {
        return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
      },
      formTitle () {
        return this.$t('elearning_tim.id_card')
      },
      columns () {
        const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          // { label: this.$t('elearning_iabm.registration_for'), class: 'text-center' },
          // { label: this.$t('globalTrans.office'), class: 'text-center' },
          { label: this.$t('globalTrans.name'), class: 'text-center' },
          { label: this.$t('globalTrans.designation'), class: 'text-center' },
          // { label: this.$t('globalTrans.organization'), class: 'text-center' },
          { label: this.$t('elearning_tim.office_address'), class: 'text-center' },
          { label: this.$t('globalTrans.mobile'), class: 'text-center' },
          { label: this.$t('globalTrans.email'), class: 'text-center wid' },
          { label: this.$t('elearning_iabm.circular_memo_no'), class: 'text-left wid' },
          { label: this.$t('elearning_config.training_title'), class: 'text-center' },
          { label: this.$t('elearning_iabm.batch_no'), class: 'text-left' },
          // { label: this.$t('elearning_config.organization'), class: 'text-center' },
          { label: this.$t('globalTrans.print'), class: 'text-center' }
        ]

        let keys = []

        if (this.$i18n.locale === 'bn') {
            keys = [
            { key: 'index' },
            // { key: 'user_type_name_bn' },
            { key: 'name_bn' },
            { key: 'designation' },
            // { key: 'professional_org_id' },
            { key: 'office' },
            { key: 'mobile' },
            { key: 'email' },
            { key: 'circular_memo_no' },
            { key: 'training_title_bn' },
            { key: 'batch_name_bn' },
            // { key: 'org_bn' },
            { key: 'action' }
            ]
        } else {
            keys = [
            { key: 'index' },
            // { key: 'user_type_name' },
            // { key: 'org' },
            // { key: 'training_title' },
            // { key: 'batch_name' },
            { key: 'name' },
            { key: 'designation' },
            // { key: 'professional_org_id' },
            { key: 'office' },
            { key: 'mobile' },
            { key: 'email' },
            { key: 'circular_memo_no' },
            { key: 'training_title' },
            { key: 'batch_name' },
            // { key: 'org' },
            // { key: 'circular_memo_no' },
            { key: 'action' }
            ]
        }

        return labels.map((item, index) => {
            return Object.assign(item, keys[index])
        })
      }
    },
    watch: {
      selectedItem: function () {
        if (this.selectedItem.length === this.listData.length) {
          this.selectAll = true
        }
        if (this.selectedItem.length < this.listData.length) {
          this.selectAll = false
        }
      },
      'search.training_type_id': function (newValue) {
        this.trainingCategoryList = this.getCategoryList(newValue)
      },
      'search.training_category_id': function (newValue) {
        this.trainingTitleList = this.getTrainingTitleList(newValue)
      }
    },
    async created () {
      this.search = Object.assign({}, this.search, {
        fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId
      })
      if (this.$store.state.Auth.activeRoleId !== 1) {
        this.search.org_id = this.$store.state.Auth.authUser.org_id
        if (this.$store.state.Auth.authUser.is_org_admin !== 1 && parseInt(this.$store.state.Auth.authUser.org_id) === 12) {
          this.search.coordinator_id = this.$store.state.Auth.authUser.user_id
        }
      }
        this.loadData()
    },
    mounted () {
      core.index()
    },
    methods: {
        getDesignationData (item, type) {
            if (parseInt(item.not_here_designation) === 0 && parseInt(item.profession_type) === 1) {
                let desigObj
                if (type === 'admin') {
                desigObj = this.$store.state.commonObj.designationList.find(designation => designation.value === item.designation_id)
                }
                if (type === 'panel') {
                desigObj = this.$store.state.ExternalUserIrrigation.commonObj.designationList.find(designation => designation.value === item.designation_id)
                }
                if (typeof desigObj !== 'undefined') {
                  item.designation_data = this.$i18n.locale === 'en' ? desigObj.text_en : desigObj.text_bn
                    return this.$i18n.locale === 'en' ? desigObj.text_en : desigObj.text_bn
                } else {
                  item.designation_data = ''
                    return ''
                }
            } else {
                item.designation_data = this.$i18n.locale === 'en' ? item.designation_en : item.designation_bn
                return this.$i18n.locale === 'en' ? item.designation_en : item.designation_bn
            }
        },
      checkAll () {
        if (this.selectAll) {
          this.selectedItem.splice(0, this.selectedItem.length)
          this.listData.forEach(element => this.selectedItem.push(element.id))
        } else {
          this.selectedItem.splice(0, this.selectedItem.length)
        }
      },
      async pdfExportID () {
        if (this.search.training_title_id) {
          if (this.selectAll === true) {
            this.loading = true
            // const obj = {
            //   selected_itm: this.selectedItem
            // }
            const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale }, this.search)
            const result = await RestApi.getPdfData(trainingElearningServiceBaseUrl, idCardPDFList, params)
            var blob = new Blob([result], {
              type: 'application/pdf'
            })
            // var url = window.URL.createObjectURL(blob)
            // window.open(url).print()
            // Create a link element
            const link = document.createElement('a')

            // Set the download attribute with the desired file name
            link.download = 'trainee_name_badge.pdf'

            // Create a URL for the Blob and set it as the link's href
            link.href = window.URL.createObjectURL(blob)

            // Append the link to the document body
            document.body.appendChild(link)

            // Trigger a click event on the link to start the download
            link.click()

            // Remove the link from the document body
            document.body.removeChild(link)
            this.loading = false
            } else {
            this.$toast.error({
              title: 'Error',
              message: 'Please Select The Check Box',
              color: '#D6E09B'
            })
          }
        } else {
          this.$toast.error({
            title: 'Error',
            message: 'Please Select The Training Title',
            color: '#D6E09B'
          })
        }
      },
      async pdfExportIdWord () {
        if (this.search.training_title_id) {
          this.loading = true
          const obj = {
            selected_itm: this.selectedItem
          }
          const params = Object.assign({ request_type: 'word', local: this.$i18n.locale }, obj)
          this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })

          try {
            const result = await RestApi.getPdfData(trainingElearningServiceBaseUrl, idCardWordList, params)

            // Create a Blob and object URL
            var blob = new Blob([result], {
              type: 'application/msword' // Use 'application/msword' for Word documents
            })

            var url = window.URL.createObjectURL(blob)

            // Create a temporary link element to trigger the download
            var a = document.createElement('a')
            a.style.display = 'none'
            a.href = url
            a.download = 'trainee_name_badge.docx' // Set the desired filename with .docx extension for Word documents

            // Trigger the download and cleanup
            document.body.appendChild(a)
            a.click()
            window.URL.revokeObjectURL(url)
          } catch (error) {
            // Handle any errors here
          } finally {
            this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
          }
      }
    },
      getOrgName (id) {
        const trainingType = this.$store.state.commonObj.organizationProfileList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
          return trainingType !== undefined ? trainingType.text_bn : ''
        } else {
          return trainingType !== undefined ? trainingType.text_en : ''
        }
      },
      getDesignation (id) {
        const designation = this.$store.state.commonObj.designationList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
          return designation !== undefined ? designation.text_bn : ''
        } else {
          return designation !== undefined ? designation.text_en : ''
        }
      },
      getOfficeName (id) {
        const office = this.$store.state.commonObj.officeList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
          return office !== undefined ? office.text_bn : ''
        } else {
          return office !== undefined ? office.text_en : ''
        }
      },
      searchIt (search) {
        this.search = search
      },
      async searchData () {
        this.loadData()
      },
      getCategoryList (typeId) {
        const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
        if (typeId) {
          return trainingCategoryList.filter(item => item.training_type_id === typeId)
        }
        return trainingCategoryList
      },
      getTrainingTitleList (categoryId) {
        const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
        if (categoryId) {
          return trainingTitleList.filter(item => item.training_category_id === categoryId)
        }
        return trainingTitleList
      },
      loadData () {
        const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.getData(trainingElearningServiceBaseUrl, traineeIdCards, params).then(response => {
          if (response.success) {
              this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
              this.paginationData(response.data)
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        })
      },
      getCustomDataList (data) {
        const listData = data.map(item => {
           const customItem = {}
           const userTypeObj = this.userTypeList.find(userType => userType.value === item.registration_for)
            if (typeof userTypeObj !== 'undefined') {
              customItem.user_type_name = userTypeObj.text_en
              customItem.user_type_name_bn = userTypeObj.text_bn
            } else {
              customItem.user_type_name = ''
              customItem.user_type_name_bn = ''
            }
          const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
          const orgReportHeadObj = this.$store.state.TrainingElearning.commonObj.trainingReportHeadList.find(doc => doc.org_id === parseInt(item.org_id))
          const orgData = {}
          if (typeof orgObj !== 'undefined') {
            orgData.org = orgObj.text_en
            orgData.org_bn = orgObj.text_bn
            orgData.org_address = orgObj.address
            orgData.org_address_bn = orgObj.address_bn
            orgData.right_logo = orgReportHeadObj.right_logo
          } else {
            orgData.org = ''
            orgData.org_bn = ''
            orgData.org_address = ''
            orgData.org_address_bn = ''
            orgData.right_logo = ''
          }
          const officeObj = this.$store.state.commonObj.officeList.find(office => office.value === parseInt(item.professional_office_id))
          const officeData = {}
          if (typeof officeObj !== 'undefined') {
            officeData.office_en = officeObj.text_en
            officeData.office_bn = officeObj.text_bn
            officeData.address = officeObj.address
            officeData.address_bn = officeObj.address_bn
          } else {
            officeData.office_en = ''
            officeData.office_bn = ''
            officeData.address = ''
            officeData.address_bn = ''
          }
          const trainingTypeListObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(obj => obj.value === item.training_type_id)
          const trainingTypeData = {
            training_type: trainingTypeListObj?.text_en,
            training_type_bn: trainingTypeListObj?.text_bn
          }
          const trainingTitleListObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(obj => obj.value === item.training_title_id)
          const trainingTitleData = {
            training_title: trainingTitleListObj?.text_en,
            training_title_bn: trainingTitleListObj?.text_bn
          }
          const batchObj = this.allBatchList.find(batch => batch.value === parseInt(item.batch_no))
          const batchData = {}
          if (typeof batchObj !== 'undefined') {
            batchData.batch_name = batchObj.text_en
            batchData.batch_name_bn = batchObj.text_bn
          } else {
            batchData.batch_name = ''
            batchData.batch_name_bn = ''
          }

          return Object.assign({}, item, orgData, officeData, trainingTypeData, trainingTitleData, customItem, batchData)
        })
        return listData
      },
      pdfExport () {
        const reportTitle = this.$i18n.locale === 'en' ? 'Trainer Profiles' : 'প্রশিক্ষকের প্রোফাইল'
        ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/fertilizer/config/report-heading/detail', 12, reportTitle, this)
      }
    }
}
</script>
<style>
.wid span {
    display: block;
    width: 100px;
    overflow-wrap: anywhere;
}
</style>
